import {createMuiTheme,} from '@material-ui/core'
import {amber} from '@material-ui/core/colors'

export default createMuiTheme({
  palette: {},
  typography: {
    useNextVariants: true,
    fontFamily: '"Montserrat"',
  },
  layout: {
    contentWidth: 1024,
    heroTotalHeight: 560,
    cardFloatUp: 120,
  },
  favorite: {
    color: amber[500]
  },
  overrides: {
    MuiIconButton: {
        root: {
            padding: 9,   // MUI-Table needs this to show multiple actions
        },
    },
    MuiChip: {
      avatarChildren: {
        fontSize: 19
      },
    }
  },
})

