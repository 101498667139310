import React, {useCallback, useRef, useState} from 'react';

import MaterialTable from 'material-table';

import {useApi} from 'lib/api';
import {useAsyncMemo, wrapAsync} from 'lib/util';

import DynamoTable from 'components/DynamoTable';
import ResourceLink, {linkTo} from 'components/ResourceLink';
import ClientParamsForm from 'components/ClientParamsForm';


import BasePage from './BasePage'

export default function ClientCollection({
    history, location, match,
    ...props
}) {
    const {api} = useApi();
    const tableRef = useRef();
    const fileRef = useRef();
    const [curClient, setCurClient] = useState();

    const clientsLink = useAsyncMemo(async () => {
        let dir = await api.directory();
        return dir.links['client.client'];
    }, [api]);

    const fetchClients = useCallback(async options => {
        if(!api) {
            return {data: [], page: 0, totalCount: 0};
        }
        let dir = await api.directory();
        let result = await api.jsonApiTableData(dir.links['client.client'], options);
        return result;
    }, [api]);

    const onRowAdd = useCallback(async rowData => {
        let dir = await api.directory();
        let resp = await api.fetch(dir.links['client.client'], {
            method: 'POST',
            body: JSON.stringify({
                data: {
                    type: 'client',
                    attributes: {client_key: rowData.client_key},
                }
            })
        })
        let client = await resp.json();
        history.push(linkTo(client.data))
    }, [api, history])

    const onRowDelete = useCallback(async rowData => {
        const resp = prompt(`
            Deleting a client is a very serious step.

            Maybe we could give them one more chance?
            Otherwise, if you're absolutely certain, you can type DELETE
            below to confirm you really want to delete this row.
            `, 'No, I changed my mind!'
        );
        if(resp !== 'DELETE') {
            alert('Delete cancelled');
        } else {
            let url = new URL(rowData.links.self);
            url.searchParams.set('force', '1');
            await api.fetch(url, {method: 'DELETE'});
        }
    }, [api])
    
    const saveClient = useCallback(
        wrapAsync(async client => {
            console.log('Submitting client', client);
            let resp = await api.fetch(client.links.self, {
                method: 'PATCH',
                body: JSON.stringify({data: client})
            });
            tableRef.current.onQueryChange();
        }),
        [api, tableRef]
    )

    const download = useCallback(
        wrapAsync(async (ev, rowData) => {
            let resp = await api.fetch(rowData.links.downloadLink, {
                method: 'POST',
                body: JSON.stringify({expires_in: 50}),
            })
            let json = await resp.json();
            console.log('About to download', json.href);
            window.open(json.href, '_blank')
        })
    )

    const upload = useCallback(
        (ev, rowData) => {
            setCurClient(rowData)
            fileRef.current.click()
        },
        [fileRef, setCurClient]
    )

    const handleUpload = useCallback(
        wrapAsync(async (ev, ...args) => {
            console.log('Got event', ev, args)
            console.log('File is', fileRef.current.files[0])
            console.log('client is', curClient)
            try {
                await api.fetch(
                    curClient.links.uploadLink, {
                        method: 'PUT',
                        body: fileRef.current.files[0],
                    }
                )
                tableRef.current.onQueryChange();
            } finally {
                fileRef.current.value = null;
            }
        }),
        [fileRef, curClient]
    )

    return (
        <BasePage pageTitle="Clients">
            <input ref={fileRef} type="file" style={{display: 'none'}}
                onChange={handleUpload}
            />
            <DynamoTable title="Clients (new)"
                columns={[
                    {title: 'Name', render: client => <ResourceLink res={client}/>, field: 'client_key'}
                ]}
                link={clientsLink}
                editable={{onRowAdd, onRowDelete}}
                detailPanel={rowData => <ClientParamsForm client={rowData} onSave={saveClient}/>}
                actions={[
                    {icon: 'cloud_download', tooltip: 'Download client config (xlsx)', onClick: download},
                    {icon: 'cloud_upload', tooltip: 'Upload client config (xlsx)', onClick: upload},
                ]}
            />
        </BasePage>
    )
}
