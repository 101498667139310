import React from 'react';
import {Link} from 'react-router-dom';

import {useRawApi} from 'lib/api';
import {wrapAsync} from 'lib/util';

import AuthPage from './AuthPage';

import {url_for} from 'routes';

const getToken = wrapAsync(async (api, history, setError) => {
    let params = new URL(window.location).searchParams;
    let code = params.get('code');
    let state = params.get('state');
    let error = params.get('error');
    let error_description = params.get('error_description');
    if(error) {
        setError({error, description: error_description})
        return;
    }

    try {
        let authData = await api.login({
            grant_type: 'authorization_code',
            code, state,
        })

        history.push(url_for('clients'))
        return authData;
    } catch(error) {
        console.log('Error fetching CORS token', error);
        setError({error: 'Error obtaining token', description: error.toString()})
    }
});


function ErrorDisplay({error, description}) {
    return (
        <div>
            <h4>{error}</h4>
            <p>{description}</p>
            <Link to={url_for('home')}>Return to login</Link>
        </div>
    )
}


export default function Callback({history}) {
    const [initialized, setInitialized] = React.useState(false);
    const [error, setError] = React.useState();
    const {api} = useRawApi();
    React.useEffect(() => {
        if(!initialized) {
            setInitialized(true);
            getToken(api, history, setError);
        }
    }, [api, history, initialized])
    return (
        <AuthPage title="Logging in...">
            {error ? <ErrorDisplay error={error.error} description={error.description}/> : null }
        </AuthPage>
    );
}