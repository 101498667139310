import React, {useCallback} from 'react';
import fp from 'lodash/fp';

import {makeStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import {useAlerts} from 'lib/alert';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

function AlertMessage({msg}) {
    const {cancelAlert} = useAlerts()
    const onClose = useCallback(e => {
        e.preventDefault();
        cancelAlert(msg.key)
    }, [cancelAlert, msg]);
    return <Alert severity={msg.severity} onClose={onClose}>{msg.message}</Alert>
}

export default function Alerts() {
    const classes = useStyles();
    const {messages, cancelAlert} = useAlerts();

    return (
        <div className="classes.root">
            {fp.map(msg => <AlertMessage key={msg.key} msg={msg}/>, messages)}
        </div>
    );
}

