import React from 'react';

import LoginButton from 'lib/components/LoginButton';

import {url_for} from 'routes';

import AuthPage from './AuthPage';


function LoginForm({redirect_uri}) {
    return (
        <div>
            <div>
                <h5>Sign in with your Google Account</h5>
            {/*
                <LoginButton fullWidth
                    provider_id="google" redirect_uri={redirect_uri}
                    variant="contained" color="primary"
                >
                    Sign in
                </LoginButton>
                <p>Having trouble with getting signed out? Try the login button below:</p>
            */}
                <LoginButton fullWidth
                    provider_id="google" redirect_uri={redirect_uri}
                    extra={{prompt: 'consent'}}
                    variant="contained" color="primary"
                >
                    Sign in
                </LoginButton>
            </div>
        </div>
    );
}

export default function LoginPage() {
    const redirect_uri = new URL(url_for('callback'), window.location);
    return (
        <AuthPage title="Sign in | Thanos">
            <LoginForm redirect_uri={redirect_uri}/>
        </AuthPage>
    );
}