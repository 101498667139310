import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import fp from 'lodash/fp';

import {Breadcrumbs, Tabs, Tab, Box} from '@material-ui/core';

import BasePage from './BasePage'

import {useApi} from 'lib/api';
import {useAsyncMemo} from 'lib/util';
import {url_for} from 'routes';

import ResourceLink from 'components/ResourceLink';
import ForecastJobTable from 'components/ForecastJobTable';
import OptimizationJobTable from 'components/OptimizationJobTable';

const Title = ({ms}) => (
    <Breadcrumbs>
        <Link to={url_for('clients')}>Clients</Link>
        <ResourceLink res={ms} rel="client"/>
        <ResourceLink res={ms}/>
    </Breadcrumbs>
)


export default function ModelSet({location, ...props}) {
    const {api} = useApi();
    const [tab, setTab] = useState(0);
    const ms = useAsyncMemo(async () => {
        console.log('Fetch modelSEt with location', location)
        let link = location.state.link;
        let resp = await api.fetch(location.state.link);
        let json = await resp.json();
        console.log('got modelset with', resp, json)
        return json;
    }, [api])
    const client = useAsyncMemo(async () => {
        let link = fp.get('data.relationships.client.links.related', ms);
        let resp = await api.fetch(link);
        let json = await resp.json();
        console.log('got client with', resp, json)
        return json;
    }, [api, ms])
    console.log({ms})
    const optimizationJobsLink = fp.get('data.relationships.optimizationJobs.links.related', ms)
    const forecastJobsLink = fp.get('data.relationships.forecastJobs.links.related', ms)
    const pageTitle = fp.get('data.id', ms);
    return (
        <BasePage pageTitle={pageTitle} 
            title={<Title client={fp.get('data', client)} ms={fp.get('data', ms)}/>}
        >
            <Tabs value={tab} onChange={(e,val) => setTab(val)}>
                <Tab label="Forecast" />
                <Tab label="Optimization"/>
            </Tabs>
            {tab === 0 && <ForecastJobTable title="Forecast Jobs" link={forecastJobsLink} /> }
            {tab === 1 && <OptimizationJobTable title="Optimization Jobs" link={optimizationJobsLink}/> }
            
        </BasePage>
    )
}
