import React, {useCallback, useMemo} from 'react';
import fp from 'lodash/fp';

import {TextField, MenuItem} from '@material-ui/core';
import MaterialTable from 'material-table';

import {ByDayView, ByDayEdit} from './ByDay';


function SelectField({options, value, onChange}) {
    const handleChange = useCallback(
        e => onChange(e.target.value),
        [onChange]
    );
    if(value === undefined || value === null) {
        debugger;
    }
    return (
        <TextField select value={value} onChange={handleChange}>
            {fp.map(
                option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>,
                options
            )}
        </TextField>
    )
}

const defaultHolidays = [
    {name: "New Year's Day", freq: "YEARLY", bymonth: 1, bymonthday: 1},
    {name: "Labor Day", freq: "YEARLY", bymonth: 9, byday: "1MO"},
    {name: "Thanksgiving", freq: "YEARLY", bymonth: 11, byday: "4TH"},
    {name: "Christmas", freq: "YEARLY", bymonth: 12, bymonthday: 25},
]

const frequencies = [
    {value: 'YEARLY', label: 'Yearly'},
    {value: 'MONTHLY', label: 'Monthly'},
    {value: 'WEEKLY', label: 'Weekly'},
    {value: 'DAILY', label: 'Daily'},
    {value: 'HOURLY', label: 'Hourly'},
    {value: 'MINUTELY', label: 'Minutely'},
    {value: 'SECONDLY', label: 'Secondly'},
]

const months = [
    {value: '1', label: 'January'},
    {value: '2', label: 'February'},
    {value: '3', label: 'March'},
    {value: '4', label: 'April'},
    {value: '5', label: 'May'},
    {value: '6', label: 'June'},
    {value: '7', label: 'July'},
    {value: '8', label: 'August'},
    {value: '9', label: 'September'},
    {value: '10', label: 'October'},
    {value: '11', label: 'November'},
    {value: '12', label: 'December'},
];

const monthsIndex = fp.flow(
    fp.map(obj => ([obj.value, obj.label])),
    fp.fromPairs
)(months);

const FrequencySelect = ({value, ...props}) => <SelectField
    options={frequencies} value={value || ''} {...props}
/>

const MonthSelect = ({value, ...props}) => <SelectField
    options={months} value={value || ''} {...props}
/>


const columns = [
    {title: 'Name', field: 'name'},
    {title: 'Frequency', field: 'freq', editComponent: FrequencySelect},
    {title: 'Day', field: 'byday', render: props => <ByDayView {...props}/>, editComponent: ByDayEdit},
    {
        title: 'Month', field: 'bymonth', editComponent: MonthSelect,
        render: ({bymonth}) => <span>of {monthsIndex[bymonth]}</span>
    },
];


export default function HolidayRulesTable({
    title, name, form, push, replace, remove
}) {
    const rowData = useMemo(
        () => fp.get(name, form.values),
        [name, form]
    );

    const onRowAdd = async rowData => {
        push(rowData);
    }

    const onRowUpdate = async (newData, oldData) => {
        const {tableData} = oldData;
        replace(tableData.id, newData)
    }

    const onRowDelete = async rowData => {
        const {tableData} = rowData;
        remove(tableData.id)
    }

    const fillDefault = useCallback(
        e => form.setFieldValue(name, defaultHolidays),
        [form, name]
    );

    return <MaterialTable title={title}
        actions={[
            {
                icon: 'restore',
                tooltip: 'Fill default holidays',
                isFreeAction: true,
                onClick: fillDefault
            }
        ]}
        options={{search: false}}
        editable={{onRowAdd, onRowUpdate, onRowDelete}}
        columns={columns}
        data={rowData}
    />
}