import React, {useState, useCallback, useMemo} from 'react';
import fp from 'lodash/fp';

import {Dialog} from '@material-ui/core';

import MaterialTable from 'material-table';

function RulesDialog({
    open, onClose, columns, data, onChange
}) {

    const handleAddRule = useCallback(async (rowData) => {
        onChange({
            columns: [...columns, rowData.name],
            data: fp.map(row => ({...row, [rowData.name]: 0.0}), data),
        });
    }, [onChange, columns, data])

    const handleRenameRule = useCallback(async (newData, oldData) => {
        const {tableData} = oldData;
        onChange({
            columns: [
                ...columns.slice(0, tableData.id + 1),
                newData.name,
                ...columns.slice(tableData.id + 2)
            ],
            data: fp.map(row => {
                let value = fp.get(oldData.name, row);
                return {
                    ...fp.omit(oldData.name, row),
                    [newData.name]: value
                };
            }, data)
        });
    }, [onChange, columns, data]);

    const handleDeleteRule = useCallback(async (rowData) => {
        const {tableData} = rowData;
        const oldName = rowData[tableData.id];
        onChange({
            columns: [
                ...columns.slice(0, tableData.id + 1),
                ...columns.slice(tableData.id + 2)
            ],
            data: fp.map(row => fp.omit(oldName, row), data)
        });
    }, [onChange, columns, data]);

    const rowData = useMemo(
        () => fp.flow(
            fp.tail,
            fp.map(column => ({name: column})),
        )(columns),
        [columns]
    )

    return (
        <Dialog open={open} onClose={onClose}>
            <MaterialTable title="Rules"
                editable={{
                    onRowAdd: handleAddRule,
                    onRowUpdate: handleRenameRule,
                    onRowDelete: handleDeleteRule,
                }}
                options={{
                    search: false,
                    pageSize: 15,
                    pageSizeOptions: [5, 15, 30],
                }}
                columns={[{title: 'Name', field: 'name'}]}
                data={rowData}
                actions={[
                    {
                        icon: 'cancel',
                        tooltip: 'Close dialog',
                        isFreeAction: true,
                        onClick: onClose,
                    }
                ]}
            />
        </Dialog>
    )
}

export default function ItemDeathRulesTable({field, form, title, ...props}) {
    const [open, setOpen] = useState(false);
    const {columns, data} = field.value;
    const handleChange = useCallback(newValue => {
        form.setFieldValue(field.name, newValue)
    }, [form, field])
    const onRowAdd = useCallback(async (rowData) => {
        handleChange({columns, data: [...data, rowData]})
    }, [columns, data, handleChange])
    const onRowUpdate = useCallback(async (newData, oldData) => {
        const {tableData} = oldData;
        handleChange({columns, data: [
            ...data.slice(0, tableData.id),
            newData,
            ...data.slice(tableData.id + 1),
         ]})
    }, [columns, data, handleChange])
    const onRowDelete = useCallback(async (rowData) => {
        const {tableData} = rowData;
        handleChange({columns, data: [
            ...data.slice(0, tableData.id),
            ...data.slice(tableData.id + 1)
        ]})
    }, [columns, data, handleChange])
    // Ensure we don't mutate the original field data
    const rowData = useMemo(
        () => fp.map(d => ({...d}), data),
        [data]
    )

    return (
        <React.Fragment>
            <RulesDialog open={open} onClose={() => setOpen(false)}
                columns={columns} data={data} onChange={handleChange}
            />
            <MaterialTable title={title}
                editable={{onRowAdd, onRowUpdate, onRowDelete}}
                options={{
                    search: false,
                    pageSize: 15,
                    pageSizeOptions: [5, 15, 30],
                }}
                columns={fp.map(name => ({title: name, field: name}), columns)}
                localization={{
                    body: {
                        addTooltip: 'Add row',
                    }
                }}
                data={rowData}
                actions={[
                    {
                        icon: 'settings',
                        tooltip: 'Edit columns',
                        isFreeAction: true,
                        onClick: (event) => setOpen(true)
                    }
                ]}
            />
        </React.Fragment>
    )
}
