import React, {useCallback, useMemo} from 'react';
import fp from 'lodash/fp';

import MaterialTable from 'material-table';

export default function TableField({field, form, title, rows, value_props={}, ...props}) {
    const data = useMemo(
        () => {
            let data = fp.map(
                row => {
                    const title = row.title;
                    const value = fp.get(row.field, form.values);
                    return {title, value};
                },
                rows
            )
            console.log('Data is', data);
            return data;
        },
        [form, rows]
    )

    const onRowUpdate = useCallback(async (newData, oldData) => {
        const {tableData} = oldData;
        const row = rows[tableData.id];
        form.setFieldValue(row.field, newData.value);
    }, [form, rows])

    return <MaterialTable title={title}
        options={{
            search: false, 
            pageSize: data.length,
            pageSizeOptions: [data.length],
        }}
        editable={{onRowUpdate}}
        columns={[
            {title: 'Name', field: 'title', editable: 'never'},
            {title: 'Value', field: 'value', ...value_props},
        ]}
        data={data}
    />
}