import React from 'react';
import {MuiThemeProvider} from '@material-ui/core';

import {Provider as AlertProvider} from 'lib/alert';
import {Provider as ApiProvider} from 'lib/api';
import {Provider as GAPIProvider} from 'lib/gapi';

import Router from './Router'
import theme from './theme'

import 'typeface-montserrat'

import './App.css';

export const ThanosApp = props => (
    <AlertProvider>
        <ApiProvider>
            <GAPIProvider
                scopes={['https://www.googleapis.com/auth/drive.readonly']}
                discoveryDocs={["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"]}
                apis={['picker']}
                appId="982223940836"
            >
                <MuiThemeProvider theme={theme}>
                      <Router />
                </MuiThemeProvider>
            </GAPIProvider>
        </ApiProvider>
    </AlertProvider>
)

export default ThanosApp;
