import React, {createContext, useContext, useEffect, useState} from "react";
import fp from 'lodash/fp';
import * as mui from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

export const GAPIContext = createContext();

const useStyles = makeStyles(theme => ({

    vbox: {
        display: 'flex',
        flexDirection: 'column',
    },
    hbox: {
        display: 'flex',
        flexDirection: 'row',
    },
    grow: {
        flex: 1,
    },
}))


export function GAPIProvider({
    client_id, appId, parent, scope, apis,
    children,
    onLoaded,
}) {
    const {gapi, google} = window;
    const [apisLoaded, setApisLoaded] = useState();
    const [token, setToken] = useState();
    const [curParent, setCurParent] = useState(parent)

    function onAuthApiLoaded() {
        // gapi.auth.authorize(
        //     {client_id, scope, immediate: false},
        //     handleAuthResult
        // );
        apiLoaded('auth');
    }

    function authorize(options={}) {
        let {force, ...restOptions} = options;
        let authOptions = {client_id, scope,
            immediate: true,
            access_type: 'offline',
            ...restOptions};
        if(force || !token) {
            console.log('authorize', authOptions);
            return gapi.auth
                .authorize(authOptions)
                .then(handleAuthResult);
        } else {
            return token;
        }
    }

    async function reAuthorize() {
        let resp = await signOut();
        console.log('signoutResponse', resp);
        return await authorize({
            force: true,
            prompt: 'select_account',
        });
    }

    function signOut() {
        setToken(undefined);
        return gapi.auth.signOut();
    }

    function handleAuthResult(authResult) {
        if (authResult && !authResult.error) {
            setToken(authResult.access_token);
            return authResult.access_token;
        }
    }

    function apiLoaded(api) {
        console.log('Loaded', api)
        let newApisLoaded = {...apisLoaded, [api]: true};
        setApisLoaded(newApisLoaded);
        if(fp.flow(
            fp.values,
            fp.every(fp.identity),
        )(newApisLoaded)) {
            if(onLoaded) {
                onLoaded()
            }
        }
    }

    useEffect(() => {
        if(!apisLoaded) {
            let newApisLoaded = fp.flow(
                fp.map(a => ([a, false])),
                fp.fromPairs,
            )(apis);
            newApisLoaded['auth'] = false;
            setApisLoaded(newApisLoaded);
            gapi.load('auth', {callback: onAuthApiLoaded});
            fp.each(api => gapi.load(api, {callback: () => apiLoaded(api)}), apis);
            setApisLoaded(newApisLoaded);
        }
    })
    console.log('In provider', {appId, token, gapi, google})
    return (
        <GAPIContext.Provider value={{
            appId, token, authorize, reAuthorize, signOut,
            parent: curParent, setParent: setCurParent
        }}>
            {children}
        </GAPIContext.Provider>
    );
}


export function Authenticator(props) {
    const {reAuthorize} = useContext(GAPIContext);
    return <mui.Button onClick={reAuthorize} {...props}/>
}


export function DrivePicker({
    name, label, value, onChange,
    error, helperText, ...props
}) {
    const {google} = window;
    const {appId, authorize, parent, setParent} = useContext(GAPIContext);
    const classes = useStyles();

    function pickerCallback(data) {
        console.log('Got picker callback', data)
        const {action, docs} = data;
        if(action === 'picked') {
            setParent(docs[0].parentId);
            let e = {target: {
                name,
                value: docs[0]
            }};
            onChange(e);
        }
    }

    async function openPicker() {
        let token = await authorize();
        let docsView = new google.picker.DocsView()
            .setParent(parent)
            .setIncludeFolders(true)
            .setMimeTypes('text/csv');
        let picker = new google.picker.PickerBuilder()
            .setAppId(appId)
            .setOAuthToken(token)
            .setTitle(`Select ${label}`)
            .addView(docsView)
            .setCallback(pickerCallback)
            .build();
        picker.setVisible(true);
    }
    return (
        <mui.FormControl className={classes.filePicker} error={error} margin="dense">
            <mui.FormGroup className={classes.hbox}>
                {label && <mui.InputLabel shrink>{label}</mui.InputLabel>}
                <mui.Input className={classes.grow} readOnly disableUnderline value={fp.getOr('', 'name', value)} {...props}/>
                <mui.Button onClick={openPicker}>
                    <mui.Icon>file_copy</mui.Icon>
                    Pick
                </mui.Button>
            </mui.FormGroup>
            {helperText && <mui.FormHelperText>{helperText}</mui.FormHelperText>}
        </mui.FormControl>
    )
}
