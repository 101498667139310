import React from 'react';
import {Link} from 'react-router-dom';
import fp from 'lodash/fp';

import {routes, url_for} from 'routes';
import {pathToRegexp} from 'path-to-regexp';


function getResourceData(res, rel=null) {
    return (rel
        ? {
            type: fp.get(`relationships.${rel}.data.type`, res),
            id: fp.get(`relationships.${rel}.data.id`, res),
            link: fp.get(`relationships.${rel}.links.related`, res),
        } : {
            type: fp.get('type', res),
            id: fp.get('id', res),
            link: fp.get('links.self', res),
        }
    )
}

function getLinkData(type, id, link) {
    const route = routes[type];
    const keys = [];
    pathToRegexp(route.path, keys);
    const keyVals = id.split('/');
    const params = fp.zip(
        fp.map(k => k.name, keys), 
        keyVals
    );
    const pathname = url_for(type, fp.fromPairs(params));
    const label = fp.last(keyVals);
    return {pathname, label};
}

export function linkTo(res, rel=null) {
    const {type, id, link} = getResourceData(res, rel);
    if(!type || !id || !link) return null;
    const {pathname} = getLinkData(type, id, link);
    return {pathname, state: {link}};
}

export default function ResourceLink({res, rel}) {
    const {type, id, link} = getResourceData(res, rel);
    if(!type || !id || !link) return null;
    const {pathname, label='?'} = getLinkData(type, id, link);
    if(!type || !id) return <div>{label}</div>;
    return <Link to={{pathname, state: {link}}}>{label}</Link>
}
