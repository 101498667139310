import React, {useCallback} from 'react';
import {
    TablePagination, TablePaginationActions, IconButton, Icon,
} from '@material-ui/core';

export function PaginationActions({page, count, onChangePage, className, ...props}) {
    const firstPage = useCallback(e => {
        onChangePage(e, 0);
    }, [onChangePage]);
    const nextPage = useCallback(e => {
        onChangePage(e, 2);
    }, [onChangePage]);
    return (
        <div className={className}>
            <IconButton disabled={page === 0} onClick={firstPage}>
                <Icon>first_page</Icon>
            </IconButton>
            <IconButton disabled={count === 2} onClick={nextPage}>
                <Icon>chevron_right</Icon>
            </IconButton>
        </div>
    )
}

export default function DynamoPagination({ActionsComponent, ...props}) {
    return <TablePagination {...props} ActionsComponent={PaginationActions} rowsPerPageOptions={[5, 10, 25]} rowsPerPage={25}/>
}