import _ from 'lodash';
import {reverse} from 'named-urls';

const routeList = [
    {name: 'home', path: '/'},
    {name: 'callback', path: '/callback'},
    {name: 'authSignout', path: '/auth/signout'},

    {name: 'clients', path: '/client'},
    {name: 'client', path: '/client/:client_key'},

    {name: 'modelSet', path: '/ms/:client_key/:model_set_name'},

    {name: 'forecastJob', path: '/forecastJob/:client_key/:model_set_name/:job_name'},
    {name: 'optimizationJob', path: '/optimizationJob/:client_key/:model_set_name/:job_name'},
];

export const routes = _.transform(routeList, (acc, r) => {
    acc[r.name] = r;
}, {})

export const routeByType = _.transform(routeList, (acc, r) => {
    if(r.type) {
        acc[r.type] = r;
    }
}, {})

export const url_for = (route, params) => {
    let {path} = routes[route];
    return reverse(path, params)
}

export const linkToRelated = (route, relationship, extraState) => {
    let id = relationship.data.id;
    return {
        pathname: url_for(route, {id}),
        state: {
            ...relationship.data,
            links: {self: relationship.links.related},
            ...extraState
        }

    }
}

export const linkToResource = (route, resource, extraState) => {
    let id = _.get(resource, 'id');
    return {
        pathname: url_for(route, {id}),
        state: {resource, ...extraState}
    }
}

export default routes;
