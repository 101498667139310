import React, {createContext, useState, useContext, useMemo, useEffect} from 'react';
import fp from 'lodash/fp';
import moment from 'moment';

import {useAsyncMemo, useStorage} from 'lib/util';
import {useAlerts} from 'lib/alert';
import history from '../history';

// export const AUTH_DATA_NAME = 'thanos.auth_data';
export const RTOK_NAME = 'thanos.rtok';

const Context = createContext();

export function Provider({children}) {
    let [authData, setAuthData] = useState(null);
    let [refreshToken, setRefreshToken] = useStorage(sessionStorage, RTOK_NAME, null);
    let {alert} = useAlerts();
    let api = useMemo(
        () => {
            return new Api({
                authData, setAuthData,
                refreshToken, setRefreshToken,
                alert,
            })
        },
        [authData, setAuthData, refreshToken, setRefreshToken, alert],
    )
    let userInfo = useAsyncMemo(
        async () => {
            if(authData) {
                return await api.fetchUserInfo();
            } else {
                return null;
            }
        },
        [api, authData],
    )

    return <Context.Provider value={{
        authData, userInfo, api
    }} children={children}/>
}

export const useRawApi = () => useContext(Context);

export const useApi = () => {
    let res = useContext(Context);
    useEffect(
        () => {
            res.api.ensureAuthorized()
        },
        [res]
    )
    return res;
}


const once = (func) => {
    let current = null;
    return async (...args) => {
        if(current) {
            return await current;
        } else {
            current = func(...args);
            try {
                return await current;
            } finally {
                current = null;
            }
        }
    }
}

class Api {
    static API_ROOT = process.env.REACT_APP_API;
    static _directory = null;

    constructor({
        authData, setAuthData,
        refreshToken, setRefreshToken,
        alert,
    }) {
        this._authData = authData;
        this._setAuthData = setAuthData;
        this._refreshToken = refreshToken;
        this._setRefreshToken = setRefreshToken;
        this._alert = alert;
    }

    ensureAuthorized = once(async () => {
        let authData = await this.authData();
        if(!authData) {
            console.log('Unauthorized!');
            window.location = '/';
            history.push('/');
        }
    });

    fetch = async (url, options={}) => {
        let headers = fp.getOr({}, 'headers')(options);
        let {catchErrors=true, ...fetchOptions} = options;

        headers = {
            'Content-Type': 'application/json',
            ...headers,
        }
        let authData = await this.authData();
        if(authData) {
            let tok = `${authData.provider_id}:${authData.access_token}`
            headers = {
                'Authorization': `${authData.token_type || 'bearer'} ${tok}`,
                ...headers
            }
        }
        fetchOptions = {
            credentials: 'include',
            ...fetchOptions,
            headers,
        }
        let result = await fetch(url, fetchOptions);
        if(result.ok) {
            return result;
        }
        else if(catchErrors) {
            console.log('ERROR!');
            let message = (
                result.headers.get('content-type') == 'application/json'
                ? await result.json()
                : await result.text()
            );
            if(message.error) {
                this._alert(message.error);
            } else if(message.errors) {
                fp.pipe([
                    fp.map(err => `${err.source.pointer}: ${err.detail}`),
                    this._alert,
                ])(message.errors);
            } else {
                this._alert(message);
            }
            return result;
        } else {
            let error = new Error(`Error in fetch: ${result.status}`)
            error.response = result;
            throw error;
        }
        console.log('result', {result})
    }

    authData = once(async () => {
        console.log('Calling authData', this);
        if(!this._authData && !this._refreshToken) {
            console.log('no auth data OR rtok!')
            return null;
        }
        const now = moment();
        const expires = fp.getOr(now, 'expires', this._authData);
        if(now >= expires) {
            console.log('Attempt an auto-refresh')
            return this.refresh()
        } else {
            // console.log('Seems to be ok', now, expires)
            return this._authData;
        }
    })

    directory = once(async () => {
        if(Api._directory === null) {
            try {
                let resp = await fetch(Api.API_ROOT);
                let data = await resp.json();
                Api._directory = data.data;
            } catch (error) {
                console.error('Error getting directory', error);
                return null;
            }
        }
        return Api._directory;
    })

    login = once(async ({grant_type, ...options}) => {
        let dir = await this.directory();
        let resp = await fetch(dir.links['oauth.token'], {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({grant_type, ...options})
        })
        if(!resp.ok) {
            let error = new Error('API Error')
            error.response = resp;
            throw error;
        }
        let authData = {
            ...this._authData,
            ...await resp.json()
        };
        let expires = (
            fp.getOr(null, 'expires_in', authData)
            ? moment().add(authData.expires_in - 5, 'seconds')
            : null
        );
        if(authData.refresh_token) {
            let rtok = `${authData.provider_id}:${authData.refresh_token}`;
            this._setRefreshToken(rtok);
        }
        authData.expires = expires;
        this._setAuthData(authData);
        console.log({authData});
        return authData;
    })

    logout = once(async logout_uri => {
        let dir = await this.directory();
        let url = new URL(dir.links['oauth.logout'])
        let resp = await this.fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                refresh_token: this._authData.refresh_token
            })
        })
        this._setAuthData(null);
        this._setRefreshToken(null);
        if(this._authData['logout_url']) {
            let url = new URL(this._authData.logout_url);
            logout_uri = new URL(logout_uri, window.location);
            url.searchParams.set('logout_uri', logout_uri.href);
            window.location = url;
        } else {
            window.location = logout_uri;
        }
        return resp;
    })

    refresh = once(() => {
        let refresh_token = this._refreshToken;
        if(refresh_token) {
            return this.login({grant_type: 'refresh_token', refresh_token});
        } else {
            return null;
        }
    })

    fetchUserInfo = once(async () => {
        let authData = await this.authData();
        if(!authData) {
            return null;
        }
        let dir = await this.directory();
        let resp = await this.fetch(dir.links['oauth.userinfo'], {catchErrors: false});
        let userInfo = await resp.json();
        console.log({userInfo})
        return userInfo;
    })

    jsonApiTableData = async (link, {
            filters, orderBy, orderDirection, page, pageSize, search, totalCount
    }) => {
        let url = new URL(link);
        url.searchParams.set('page[offset]', page * pageSize);
        url.searchParams.set('page[limit]', pageSize);
        fp.forEach(
            ({column, value}) => {
                let k = attrName(column);
                url.searchParams.set(`filter[${k}]`, value);
            },
            filters
        )
        if(orderDirection === 'asc') {
            url.searchParams.set('sort', attrName(orderBy));
        } else if(orderDirection === 'desc') {
            url.searchParams.set('sort', '-' + attrName(orderBy));
        }
        let resp = await this.fetch(url);
        let data = await resp.json();
        console.log('Got data', data)
        return {
            data: data.data,
            page: page,
            links: data.links,
            included: data.included,
            totalCount: data.meta.total,
        };
    }

    pccJsonApiTableData = async (link, {
            filters, orderBy, orderDirection, page, pageSize, search, totalCount
    }) => {
        let url = new URL(link);
        url.searchParams.set('page[page]', page + 1)
        url.searchParams.set('page[pageSize]', pageSize);
        fp.forEach(
            ({column, value}) => {
                let k = attrName(column);
                url.searchParams.set(`filter[${k}]`, value);
            },
            filters
        )
        if(orderDirection === 'asc') {
            url.searchParams.set('sort', attrName(orderBy));
        } else if(orderDirection === 'desc') {
            url.searchParams.set('sort', '-' + attrName(orderBy));
        }
        let resp = await this.fetch(url);
        let data = await resp.json();
        return {
            data: data.data,
            page: page,
            links: data.links,
            included: data.included,
            totalCount: data.meta.total,
        };
    }
}

const attrName = col => col.field.split('.', 2)[1];

const index = fp.flow(
    fp.groupBy('type'),
    fp.toPairs,
    fp.map(([type, objs]) => ([type, fp.groupBy('id', objs)])),
    fp.fromPairs
)

export const hydrateRelationships = ({data, included}) => {
    let includeIndex = index(included);
    const lookupRel = ({type, id}) => fp.get(`${type}.${id}`, includeIndex);
    const relationships = fp.flow(
        fp.get('relationships'),
        fp.toPairs,
        fp.map(
            ([key, value]) => {
                if(fp.isArray(value.data)) {
                    return [key, fp.map(lookupRel, value.data)]
                } else {
                    return [key, fp.first(lookupRel(value.data))]
                }
            }
        ),
        fp.fromPairs
    )
    return fp.map(
        obj => ({...obj, rel: relationships(obj)}),
        data
    );
}

console.log('API_ROOT', Api.API_ROOT)



