import React from 'react';
import fp from 'lodash/fp';

import {Button} from '@material-ui/core';

import {useRawApi} from 'lib/api';
import {useAsyncMemo} from 'lib/util';


export default function LoginButton({
    provider_id, redirect_uri, extra={}, ...props
}) {
    const {api} = useRawApi();
    const href = useAsyncMemo(
        async () => {
            let dir = await api.directory();
            console.log('dir is', dir);
            let resp = await api.fetch(dir.links['oauth.provider']);
            let json = await resp.json();
            let provider = fp.flow(
                fp.filter(p => p.id === provider_id),
                fp.first
            )(json.data);
            let url = new URL(provider.links.authorize);
            url.searchParams.set('redirect_uri', redirect_uri);
            fp.flow(
                fp.toPairs,
                fp.forEach(
                    ([k, v]) => url.searchParams.set(k, v)
                )
            )(extra);
            return url.href;
        },
        [api, provider_id]
    )
    return <Button href={href} {...props}/>
}
