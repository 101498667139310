import React from 'react';
import DocumentTitle from 'react-document-title';

import {makeStyles} from '@material-ui/core/styles';
import {Paper} from '@material-ui/core';

import CoverImage from 'lib/components/CoverImage';

const useStyles = makeStyles(theme => ({
    logo: {
        width: 360,
    },
    content: {
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        '& h1': theme.typography.h1,
        '& h2': theme.typography.h2,
        '& h3': theme.typography.h3,
        '& h4': theme.typography.h4,
        '& h5': theme.typography.h5,
        '& p': theme.typography.body1,
    },
    paper: {
        width: 380,
        margin: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: 20,
    },
}))

export default function AuthPage({
    title, children,
}) {
    const classes = useStyles()
    return (
        <DocumentTitle title={`${title}`}>
            <div className={classes.content}>
                <CoverImage src="/s/images/writing-at-desk_925x.jpg" dark={0.5}/>
                <Paper className={classes.paper}>
                    {children}
                </Paper>
            </div>
        </DocumentTitle>
    );
}
