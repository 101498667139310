import React, {useState} from 'react';

import {Formik, Field, FieldArray, Form} from 'formik';
import * as Yup from 'yup';

import {Button, Tabs, Tab} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import TableField from 'components/TableField';
import ItemDeathRulesTable from 'components/ItemDeathRulesTable'
import ScenarioRulesTable from 'components/ScenarioRulesTable'
import HolidayRulesTable from 'components/HolidayRulesTable'


const useStyles = makeStyles(theme => ({
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
}))


const ClientSchema = Yup.object().shape({
    attributes: Yup.object().shape({
        sim_days_per_trial: Yup.number().integer().required('required'),
        sim_min_trials: Yup.number().integer().required('required'),
        sim_max_trials: Yup.number().integer().required('required'),
        sim_trials_threshold_low: Yup.number().required('required'),
        sim_trials_threshold_high: Yup.number().required('required'),
        sim_neighborhood: Yup.number().integer().required('required'),
        max_up_trend: Yup.number().required('required'),
        max_down_trend: Yup.number().required('required'),
        monthly_decay_lead_time: Yup.number().required('required'),
        monthly_decay_sale_price: Yup.number().required('required'),
        monthly_decay_sales_orders: Yup.number().required('required'),
        monthly_decay_sales_qty: Yup.number().required('required'),
        holidays: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('required'),
                freq: Yup.string().required('required'),
                bymonth: Yup.number().transform( cv => isNaN(cv) ? undefined : cv).integer().nullable(),
                bymonthday: Yup.number().transform( cv => isNaN(cv) ? undefined : cv).integer().nullable(),
                byday: Yup.string(),
            })
        ),
    }),
})

export default function ClientParamsForm({client, onSave}) {
    const classes = useStyles();
    const [tab, setTab] = useState('basic');

    return (
        <Formik
            validationSchema={ClientSchema}
            initialValues={client}
            onSubmit={onSave}
        >{({handleSubmit, handleReset, errors}) => (
            <Form onSubmit={handleSubmit}>
                <Tabs value={tab} onChange={(e, value) => setTab(value)}>
                    <Tab value="basic" label="Basic Params"/>
                    <Tab value="death" label="Item Death Rules"/>
                    <Tab value="scenario" label="Scenario Rules"/>
                </Tabs>
                <pre>{JSON.stringify(errors)}</pre>
                {tab === 'basic' && (
                    <Field component={TableField} title="Basic Params"
                        value_props={{type: 'numeric'}}
                        rows={[
                            {title: 'Days per trial', field: 'attributes.sim_days_per_trial'},
                            {title: 'Min trials', field: 'attributes.sim_min_trials'},
                            {title: 'Max trials', field: 'attributes.sim_max_trials'},
                            {title: 'Tolerance Low Vol', field: 'attributes.sim_trials_threshold_low'},
                            {title: 'Tolerance High Vol', field: 'attributes.sim_trials_threshold_high'},
                            {title: 'Neighbors per temp', field: 'attributes.sim_neighborhood'},
                            {title: 'Max Up Trend (fcst)', field: 'attributes.max_up_trend'},
                            {title: 'Max Down Trend (fcst)', field: 'attributes.max_down_trend'},
                            {title: 'Lead time', field: 'attributes.monthly_decay_lead_time'},
                            {title: 'Sale price', field: 'attributes.monthly_decay_sale_price'},
                            {title: 'Sales transactions', field: 'attributes.monthly_decay_sales_orders'},
                            {title: 'Sales orders', field: 'attributes.monthly_decay_sales_qty'},
                    ]}/>
                )}
                {tab === 'death' && (
                    <Field component={ItemDeathRulesTable} name="attributes.item_death_rules" title="Item Death Rules"/>
                )}
                {tab === 'scenario' && (
                    <FieldArray name="attributes.scenario_rules">
                        {props => <ScenarioRulesTable title="Scenario Rules" {...props}/>}
                    </FieldArray>
                )}
                <div className={classes.buttons}>
                    <Button type="reset" onClick={handleReset}>Reset form</Button>
                    <Button variant="contained" color="primary" type="submit">Save changes</Button>
                </div>
            </Form>
        )}</Formik>
    )
}
