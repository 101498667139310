import React, {useRef, useState, useMemo, useCallback, useEffect} from 'react';
import MaterialTable from 'material-table';

import {useApi} from 'lib/api';
import fp from 'lodash/fp';

export default function ArrayJobStatus({link}) {
    const {api} = useApi();
    const tableRef = useRef();
    const [awsJob, setAwsJob] = useState();

    async function fetchAwsJob() {
        if(!link) {
            return
        }
        console.log('fetch aws job', link)
        try {
            let resp = await api.fetch(link);
            let json = await resp.json();
            setAwsJob(json.data);
            console.log('Got ajob', json)
        } catch(e) {
            console.log('Error fetching job:', e)
            setAwsJob({});
        }
    }

    const data = useMemo(
        () => fp.flow(
            fp.get('attributes.arrayProperties.statusSummary'),
            fp.toPairs,
            fp.map(([k, v]) => ({status: k, count: v}))
        )(awsJob),
        [awsJob]
    )

    const jobId = fp.get('id', awsJob);
    const size = fp.get('attributes.arrayProperties.size', awsJob);
    const status = fp.get('attributes.status', awsJob);

    const confirmKill = useCallback(async () => {
        if(window.confirm('Do you really want to kill this job?')) {
            let resp = await api.fetch(awsJob.links.self, {method: 'DELETE'});
            fetchAwsJob();
        }
    }, [api, awsJob])

    useEffect(() => {
        if(!awsJob) {
            fetchAwsJob();
        }
    })
    console.log({awsJob})

    return (
        <div>
            <MaterialTable title={`Job ${jobId}[${size}]: ${status}`}
                options={{
                    search: false,
                    pageSize: 7,
                    pageSizeOptions: [7, 10, 20],
                }}
                columns={[
                    {title: 'Status', field: 'status'},
                    {title: 'Count', field: 'count'},
                ]}
                data={data}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh',
                        isFreeAction: true,
                        onClick: fetchAwsJob,
                    },
                    {
                        icon: 'report',
                        tooltip: 'Terminate job',
                        isFreeAction: true,
                        onClick: confirmKill,
                    }
                ]}
            />
        </div>
    )
}


