import React, {useCallback, useRef, useState, useEffect, useMemo} from 'react';
import moment from 'moment';
import fp from 'lodash/fp';

import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, MenuItem,
    TextField as MUITextField
} from '@material-ui/core';
import MaterialTable from 'material-table';
import {Formik, Form, Field} from 'formik';

import ResourceLink from 'components/ResourceLink';
import ArrayJobStatus from 'components/ArrayJobStatus';

import {useApi} from 'lib/api';
import {useAlerts} from 'lib/alert';
import {wrapAsync} from 'lib/util';

import {TextField} from './Fields';
import DynamoTable from './DynamoTable';

function NewForecastJobDialog({open, onClose, onSave}) {
    const emptyForecastJob = {
        job_name: moment().format(),
        job_type: 'sku_txn',

    }
    const onSubmit = useCallback(
        values => {
            onClose();
            onSave(values)
    }, [onClose, onSave]);
    const onReset = useCallback(
        () => onClose(),
        [onClose]
    )
    return (
        <Dialog open={open} onClose={onClose}>
            <Formik initialValues={emptyForecastJob} onSubmit={onSubmit} onReset={onReset}>{({handleReset}) => (
                <Form>
                    <DialogTitle>Create Forecast Job</DialogTitle>
                    <DialogContent>
                        <Field fullWidth name="job_name" label="Job Name"
                            component={TextField}
                        />
                        <Field fullWidth name="job_type" label="Job Type"
                            component={TextField} select>
                            <MenuItem value="sku_txn">SKU/TXN Only</MenuItem>
                            <MenuItem value="full">Full Vendor Forecast</MenuItem>
                        </Field>
                    </DialogContent>
                    <DialogActions>
                        <Button type="reset" onClick={handleReset}>Cancel</Button>
                        <Button variant="contained" color="primary" type="submit">
                            Create job
                        </Button>
                    </DialogActions>
                </Form>
            )}</Formik>
        </Dialog>
    )
}

function DetailStatus({job}) {
    return (
        <div>
            <MUITextField fullWidth multiline
                InputLabelProps={{shrink: true}}
                InputProps={{readOnly: true, disableUnderline: true}}
                value={fp.getOr('', 'attributes.builder_job_id', job)}
                label="Builder Job ID"
            />
            <MUITextField fullWidth multiline
                InputLabelProps={{shrink: true}}
                InputProps={{readOnly: true, disableUnderline: true}}
                value={fp.getOr('', 'attributes.optimizer_job_id', job)}
                label="Optimizer Job ID"
            />
            <MUITextField fullWidth multiline
                InputLabelProps={{shrink: true}}
                InputProps={{readOnly: true, disableUnderline: true}}
                value={fp.getOr('', 'attributes.finalizer_job_id', job)}
                label="Finalizer Job ID"
            />
            <MUITextField fullWidth multiline
                InputLabelProps={{shrink: true}}
                InputProps={{readOnly: true, disableUnderline: true}}
                value={fp.getOr('', 'attributes.detail', job)}
                label="Status Detail"
            />
            <ArrayJobStatus link={fp.get('relationships.optimizeJob.links.related', job)}/>
        </div>
    );
}

export default function ForecastJobJobTable({title, link}) {
    const {api} = useApi();
    const tableRef = useRef();
    const [dialogOpen, setDialogOpen] = useState(false);
    const {alert} = useAlerts();

    const beginForecastJob = useCallback(
        async (e, obj) => {
            console.log('Begin optimization job for', obj);
            let resp = await api.fetch(obj.links.self, {
                method: 'PATCH',
                body: JSON.stringify({data: {
                    ...obj,
                    attributes: {
                        ...obj.attributes,
                        status: 'START',
                    },
                }}),
            })
            await resp.json();
            tableRef.current.onQueryChange();
        },
        [api, tableRef]
    )

    const download = async (ev, tr) => {
        const downloadLink = fp.get('links.downloadLink', tr);
        let resp = await api.fetch(downloadLink, {
            method: 'POST',
            body: JSON.stringify({expires_in: 50}),
        })
        let json = await resp.json();
        console.log('About to download', json.href);
        window.open(json.href, '_blank')
        // downloadjs(json.href);
    }

    const saveForecastJob = useCallback(
        wrapAsync(async obj => {
            let resp = await api.fetch(obj.links.self, {
                method: 'PATCH',
                body: JSON.stringify({data: {
                    ...obj,
                    attributes: obj.attributes
                }}),
            })
            await resp.json();
            tableRef.current.onQueryChange();
        }),
        [api, tableRef]
    )

    const onAddForecastJob = useCallback(async data => {
        console.log(data)
        let resp = await api.fetch(link, {
            method: 'POST',
            body: JSON.stringify({
                data: {
                    type: 'forecastJob',
                    attributes: data,
                }
            })
        })
        if(!resp.ok) return;
        let json = await resp.json();
        console.log('Created optimization job', json);
        tableRef.current.onQueryChange();
    }, [api, link, tableRef])

    const onRowDelete = useCallback(async rowData => {
        const status = rowData.attributes.status;
        if(fp.indexOf(status, ['NEW', 'SUCCESS', 'ERROR']) === -1) {
            const resp = prompt(`
                The status ${status} is not valid to delete. 
                Please type DELETE to confirm you really want to delete this row.
                `, 'No, I changed my mind!'
            );
            if(resp !== 'DELETE') {
                alert('Delete cancelled');
            } else {
                let url = new URL(rowData.links.self);
                url.searchParams.set('force', '1');
                await api.fetch(url, {method: 'DELETE'});
            }
        } else {
            let resp = await api.fetch(rowData.links.self, {method: 'DELETE'});
            if(resp.ok) {
                alert('Deleted!', 'success');
            }
        }
    }, [api])

    return (
        <React.Fragment>
            <NewForecastJobDialog open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onSave={onAddForecastJob}
            />
            <DynamoTable title={title}
                link={link}
                tableRef={tableRef}
                editable={{onRowDelete}}
                columns={[
                    {
                        title: 'Name', field: 'attributes.job_name',
                        render: job => <ResourceLink res={job}/>,
                    },
                    { title: 'Type', field: 'attributes.job_type', editable: 'never'},
                    {title: 'Builder Status', field: 'attributes.status', editable: 'never'},
                    {
                        title: 'Detail', field: 'attributes.detail',
                        render: fp.flow(
                            fp.get('attributes.detail'),
                            fp.truncate({}),
                        )
                    },
                    {title: 'Remaining Sub-jobs (est.)', field: 'attributes.remaining_subjobs', editable: 'never'},
                    {title: 'Created', field: 'attributes.created', editable: 'never'},
                ]}
                detailPanel={[
                    {
                        tooltip: 'Batch job status',
                        render: job => <DetailStatus job={job}/>
                    }
                ]}
                actions={[
                    {
                        icon: 'add_box',
                        tooltip: 'Create forecast job',
                        isFreeAction: true,
                        onClick: () => setDialogOpen(true)
                    },
                    {
                        icon: 'play_arrow',
                        tooltip: 'Submit job',
                        onClick: beginForecastJob,
                    },
                    {
                        icon: 'cloud_download',
                        tooltip: 'Download (xlsx)',
                        onClick: download,
                    },
                ]}
            />
        </React.Fragment>
    )
}
