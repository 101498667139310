import React from 'react';
import {Link} from 'react-router-dom';
import fp from 'lodash/fp';

import {Breadcrumbs} from '@material-ui/core';

import BasePage from './BasePage'

import {useApi} from 'lib/api';
import {useAsyncMemo} from 'lib/util';
import {url_for} from 'routes';

import ResourceLink from 'components/ResourceLink';
import ModelSetsTable from 'components/ModelSetsTable';

const Title = ({client}) => (
    <Breadcrumbs>
        <Link to={url_for('clients')}>Clients</Link>
        <ResourceLink res={client}/>
    </Breadcrumbs>
)


export default function Client({location, ...props}) {
    const {api} = useApi();
    const client = useAsyncMemo(async () => {
        let link = location.state.link;
        let resp = await api.fetch(location.state.link);
        let json = await resp.json();
        return json;
    }, [api])
    console.log({client})
    const modelSetsLink = fp.get('data.relationships.modelSets.links.related', client)
    const pageTitle = fp.get('data.id', client);
    return (
        <BasePage pageTitle={pageTitle} title={<Title client={fp.get('data', client)}/>}>
            <ModelSetsTable title="Model Sets" link={modelSetsLink}/>
        </BasePage>
    )
}
