import React from 'react';
import fp from 'lodash/fp';

import DynamoTable from 'components/DynamoTable';

const columns = [
    {title: 'Index', field: 'attributes.array_index'},
    {title: 'Item Key', field: 'attributes.item_key'},
    {title: 'Facility Key', field: 'attributes.facility_key'},
    {title: 'Scenario', field: 'attributes.scenario'},
    {title: 'ROP', field: 'attributes.rop'},
    {title: 'PSL', field: 'attributes.psl'},
    {title: 'Daily Cost', field: 'attributes.daily_cost'},
    {title: 'Receipt Cost', field: 'attributes.daily_receipt_cost'},
    {title: 'Stockout Cost', field: 'attributes.daily_stockout_cost'},
    {title: 'Holding Cost', field: 'attributes.daily_holding_cost'},
    {title: 'Fcst txns/mo', field: 'attributes.fcst_monthly_txns'},
    {title: 'Fcst units/mo', field: 'attributes.fcst_monthly_units'},
    {title: 'Delay days', field: 'attributes.delay_days'},
    {title: 'LT[50%]', field: 'attributes.leadtime_50th'},
    {title: 'LT[90%]', field: 'attributes.leadtime_90th'},
]


export default function ScenarioResultsTable({job}) {
    return <DynamoTable title="Scenario Results"
        columns={columns}
        link={fp.get('relationships.results.links.related', job)}
        downloadLink={fp.get('links.downloadLink', job)}
    />
}
