import React, {createContext, useContext, useMemo, useEffect, useState} from 'react';
import fp from 'lodash/fp';

import {useRawApi} from 'lib/api';

const Context = createContext();

const ROOT_ID = '0B20f41-W9t1keFBDNDFNd01zOEE';

export function Provider({
    scopes, discoveryDocs, apis, children,
}) {
    const {gapi, google} = window;
    const {authData} = useRawApi();
    const [initialized, setInitialized] = useState(false);

    const docsView = useMemo(() => {
        if(!google) {
            return null
        }
        let value = new google.picker.DocsView()
            .setParent(ROOT_ID)
            .setIncludeFolders(true)
            .setMimeTypes('text/csv');
        window.docsView = value;
        return value;
    }, [google]);

    const pickerBuilder = useMemo(() => {
        console.log('Getting pickerBuilder')
        if(!google || !authData) {
            console.log('Try to get picker but', {google, authData});
            return null;
        }
        let value = new google.picker.PickerBuilder()
            .setOAuthToken(authData.access_token)
            .addView(docsView)
            .addView(google.picker.ViewId.RECENTLY_PICKED)
        ;
        console.log('Getting pickerBulider', value);
        window.pickerBuilder = value;
        return value;
    }, [authData, google, docsView])

    useEffect(() => {
        if(!authData || initialized) {
            return
        }
        gapi.load('auth', () => {
            console.log('Setting gapi token')
            gapi.client.setToken({access_token: authData.access_token});
            console.log('Calling gapi init')
            gapi.client.init({scopes, discoveryDocs})
            console.log('Loading other apis')
            fp.each(api => {
                console.log('Loading api', api);
                gapi.load(api, {callback: () => console.log('loaded', api)});
            }, apis);
        });
        setInitialized(true);
    }, [authData, initialized])

    return <Context.Provider value={{gapi, google, docsView, pickerBuilder}} children={children}/>
}

export const useGapi = () => useContext(Context);
