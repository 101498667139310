import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import fp from 'lodash/fp';

import {Breadcrumbs, TextField} from '@material-ui/core';

import BasePage from './BasePage'

import {useApi} from 'lib/api';
import {useAsyncMemo} from 'lib/util';
import {url_for} from 'routes';

import ResourceLink from 'components/ResourceLink';

import JobKiller from 'components/JobKiller';
import ScenarioResultsTable from 'components/ScenarioResultsTable';


const Title = ({job}) => (
    <Breadcrumbs>
        <Link to={url_for('clients')}>Clients</Link>
        <ResourceLink res={job} rel="client"/>
        <ResourceLink res={job} rel="modelSet"/>
        <ResourceLink res={job}/>
    </Breadcrumbs>
)

export default function OptimizationJob({location, ...props}) {
    const {api} = useApi();

    const job = useAsyncMemo(async () => {
        let link = location.state.link;
        let resp = await api.fetch(link);
        let json = await resp.json();
        return json.data;
    }, [api])

    return (
        <BasePage pageTitle={fp.get('id', job)} title={<Title job={job}/>}>
            <h4>
                {fp.get('attributes.job_name', job)}: {fp.get('attributes.status', job)}
            </h4>
            <TextField fullWidth multiline
                InputLabelProps={{shrink: true}}
                InputProps={{readOnly: true, disableUnderline: true}}
                value={fp.getOr('', 'attributes.detail', job)}
                label="Status Detail"
            />
            <ScenarioResultsTable title="Scenario Results" job={job}/>
        </BasePage>
    )
}
