import React, {createContext, useState, useContext, useCallback} from 'react';
import fp from 'lodash/fp';

const Context = createContext();
let nextKey = 0;

export const useAlerts = () => useContext(Context);

export function Provider({children}) {
    let [messages, setMessages] = useState([]);

    const alert = useCallback(
        (message, severity='error') => {
            setMessages([
                ...messages, 
                {key: nextKey, message, severity},
            ]);
            nextKey++;
        }, [messages, setMessages] );

    const cancelAlert = useCallback(
        key => {
            setMessages(fp.filter(
                msg => msg.key != key,
                messages
            ));
        }, [messages, setMessages] );

    return <Context.Provider value={{
        messages, setMessages, alert, cancelAlert
    }} children={children}/>
}

