import React, {useCallback, useRef, useState} from 'react';
import moment from 'moment';
import fp from 'lodash/fp';

import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button,
    TextField as MUITextField
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';

import ResourceLink from 'components/ResourceLink';
import ModelSetForm from 'components/ModelSetForm';

import {useApi} from 'lib/api';
import {wrapAsync} from 'lib/util';

import {TextField} from './Fields';
import DynamoTable from './DynamoTable';

function NewModelSetDialog({open, onClose, onSave}) {
    const emptyModelSet = {
        model_set_name: moment().format()
    }
    const onSubmit = useCallback(
        values => {
            onClose();
            onSave(values)
    }, [onClose, onSave]);
    const onReset = useCallback(
        () => onClose(),
        [onClose]
    )
    return (
        <Dialog open={open} onClose={onClose}>
            <Formik initialValues={emptyModelSet} onSubmit={onSubmit} onReset={onReset}>{({handleReset}) => (
                <Form>
                    <DialogTitle>Create Model Set</DialogTitle>
                    <DialogContent>
                        <Field fullWidth name="model_set_name" label="Model Set Name"
                            component={TextField}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button type="reset" onClick={handleReset}>Cancel</Button>
                        <Button variant="contained" color="primary" type="submit">
                            Create model set
                        </Button>
                    </DialogActions>
                </Form>
            )}</Formik>
        </Dialog>
    )
}


export default function ModelSetJobTable({title, link}) {
    const {api} = useApi();
    const tableRef = useRef();
    const [dialogOpen, setDialogOpen] = useState(false);

    const beginModelSet = useCallback(
        async (e, obj) => {
            console.log('Create model set for', obj);
            let resp = await api.fetch(obj.links.self, {
                method: 'PATCH',
                body: JSON.stringify({data: {
                    ...obj,
                    attributes: {
                        ...obj.attributes,
                        status: 'START',
                    },
                }}),
            })
            await resp.json();
            tableRef.current.onQueryChange();
        },
        [api, tableRef]
    )

    const saveModelSet = useCallback(
        wrapAsync(async obj => {
            let resp = await api.fetch(obj.links.self, {
                method: 'PATCH',
                body: JSON.stringify({data: {
                    ...obj,
                    attributes: obj.attributes
                }}),
            })
            await resp.json();
            tableRef.current.onQueryChange();
        }),
        [api, tableRef]
    )

    const onAddModelSet = useCallback(async data => {
        console.log(data)
        let resp = await api.fetch(link, {
            method: 'POST',
            body: JSON.stringify({
                data: {
                    type: 'modelSet',
                    attributes: data,
                }
            })
        })
        let json = await resp.json();
        console.log('Created model set', json);
        tableRef.current.onQueryChange();
    }, [api, link, tableRef])

    const onRowDelete = useCallback(async rowData => {
        await api.fetch(rowData.links.self, {method: 'DELETE'});
    }, [api])

    return (
        <React.Fragment>
            <NewModelSetDialog open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onSave={onAddModelSet}
            />
            <DynamoTable title={title}
                link={link}
                tableRef={tableRef}
                editable={{onRowDelete}}
                columns={[
                    {
                        title: 'Name', field: 'attributes.model_set_name',
                        render: ms => <ResourceLink res={ms}/>,
                    },
                    {title: 'Status', field: 'attributes.status', editable: 'never'},
                    {
                        title: 'Detail', field: 'attributes.detail',
                        render: fp.flow(
                            fp.get('attributes.detail'),
                            fp.truncate({}),
                        )
                    },
                    {title: 'Created', field: 'attributes.created', editable: 'never'},
                ]}
                detailPanel={[
                    {
                        tooltip: 'Detailed status',
                        render: ms => <MUITextField fullWidth multiline
                            InputLabelProps={{shrink: true}}
                            InputProps={{readOnly: true, disableUnderline: true}}
                            value={fp.getOr('', 'attributes.detail', ms)}
                            label="Status Detail"
                        />
                    },
                    {
                        icon: 'settings', tooltip: 'Settings',
                        render: ms => <ModelSetForm ms={ms} onSave={saveModelSet}/>
                    },

                ]}
                actions={[
                    {
                        icon: 'add_box',
                        tooltip: 'Create model set',
                        isFreeAction: true,
                        onClick: () => setDialogOpen(true)
                    },
                    {
                        icon: 'play_arrow',
                        tooltip: 'Build model set',
                        onClick: beginModelSet,
                    },
                ]}
            />
        </React.Fragment>
    )
}
