import React, {useState} from 'react';

import * as mui from '@material-ui/core';

export default function IconMenu({
    icon='more_vert', children, help,
}) {
    const [anchor, setAnchor] = useState(null);
    return (
        <React.Fragment>
            <mui.Tooltip title={help}>
                <mui.IconButton onClick={e => setAnchor(e.target)}>
                    <mui.Icon>{icon}</mui.Icon>
                </mui.IconButton>
            </mui.Tooltip>
            <mui.Menu
                anchorEl={anchor}
                open={anchor != null}
                onClose={() => setAnchor(null)}>
                {children}
            </mui.Menu>
        </React.Fragment>
    );
}

