import React, {useCallback} from 'react';
import moment from 'moment';
import fp from 'lodash/fp';

import {TextField as MUITextField, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import MaterialTable from 'material-table';

import {Formik, Form, Field} from 'formik';

import {makeField} from './Fields';
import {useGapi} from 'lib/gapi';
import PickerField from './PickerField';

const TextField = makeField(MUITextField);

export const emptyModelSet = {};

const useStyles = makeStyles(theme => ({
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
}))


const makeColumns = () => ([
    {title: 'Name', field: 'label', editable: 'never'},
    {
        title: 'Filename',
        field: 'value',
        render: ({value}) => <span>{value && value.name}</span>,
    },
])

function FilesField({
    field, form, ...props
}) {
    const {value, onChange} = field;
    const {gapi, docsView, pickerBuilder} = useGapi();

    const pickerCallback = name => e => {
        console.log('callback', {name, e})
        if(e.action === 'picked') {
            let fieldName = `${field.name}.${name}`;
            let newValue = fp.pick(['id', 'name', 'parentId'], e.docs[0]);
            console.log('doc picked:', e.docs[0]);
            form.setFieldValue(fieldName, newValue);
        }
    }

    const handlePicker = useCallback(
        async (e, rowData) => {

            let pb = pickerBuilder
                .setTitle(`Select ${rowData.label} file`)
                .setCallback(pickerCallback(rowData.name));
            let picker = pb.build();
            picker.setVisible(true);
        },
        [pickerBuilder]
    )

    if(!value) {
        return null;
    }

    return (
        <MaterialTable title="ModelSet files"
            options={{
                search: false,
                pageSize: 10,
                pageSizeOptions: [5, 10, 25]
            }}
            columns={makeColumns()}
            data={[
                {name: 'buckets', label: 'Buckets', value: fp.get('buckets', value)},
                {name: 'delay_days', label: 'Delay_days', value: fp.get('delay_days', value)},
                {name: 'margin_floor', label: 'Margin_floor', value: fp.get('margin_floor', value)},
                {name: 'exceptions', label: 'Exceptions', value: fp.get('exceptions', value)},
                {name: 'products', label: 'Products', value: fp.get('products', value)},
                {name: 'receiving', label: 'Receiving', value: fp.get('receiving', value)},
                {name: 'sales', label: 'Sales', value: fp.get('sales', value)},
            ]}
            actions={[
                {
                    icon: 'insert_drive_file',
                    tooltip: 'Set file',
                    onClick: handlePicker
                }
            ]}
        />
    )
}

export default function ModelSetForm({ms, isNew, onSave}) {
    const classes = useStyles();
    const onSubmit = useCallback(data => {
        onSave({...ms, ...data})
    }, [onSave])
    return <Formik initialValues={ms} onSubmit={onSubmit}>{({handleReset}) => (
        <Form>
            <Field fullWidth inputProps={{readOnly: !isNew}}
                component={TextField} name='attributes.model_set_name'
                label="ModelSet Name"/>
            <Field component={FilesField} name='attributes'/>
            <div className={classes.buttons}>
                <Button type="reset" onClick={handleReset}>Reset form</Button>
                <Button variant="contained" color="primary" type="submit">Save changes</Button>
            </div>
        </Form>
    )}</Formik>

}

