import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import fp from 'lodash/fp';

import {makeStyles} from '@material-ui/core/styles';
import {
    IconButton, Icon, MenuItem, Button, Toolbar, Drawer, Paper,
    List, ListItem, ListItemIcon, ListItemText,
    AppBar as MUIAppBar,
} from '@material-ui/core';

import {url_for} from 'routes';

import {useApi} from 'lib/api';
import IconMenu from 'components/IconMenu';
import Alerts from 'components/Alerts';

const useStyles = makeStyles(theme => ({
    page: {
        '& h1': theme.typography.h1,
        '& h2': theme.typography.h2,
        '& h3': theme.typography.h3,
        '& h4': theme.typography.h4,
        '& h5': theme.typography.h5,
        '& p': theme.typography.body1,
    },
    container: {
        padding: theme.spacing(1),
    },
    appBar: {
        '& h1': theme.typography.h5,
        '& a': {
            textDecoration: 'none'
        }
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    spacer: {
        width: theme.spacing(2),
    },
    hidden: {
        display: 'none',
    },

}))

const links = [
    {route: 'clients', primary: 'Clients', icon: 'person'},
]

function BackButton({href, onClick}) {
    const classes = useStyles();
    return (
        <IconButton
            className={classes.menuButton}
            component={href && Link}
            to={href} onClick={onClick}
        >
            <Icon>arrow_back</Icon>
        </IconButton>
    )
}

function MenuButton({onClick}) {
    const classes = useStyles();
    return (
        <IconButton className={classes.menuButton} onClick={onClick}>
            <Icon>menu</Icon>
        </IconButton>
    )
}

const RightMenu = ({onSignout}) => (
    <IconMenu help="Settings">
        <MenuItem icon="power_settings_new" onClick={onSignout}>
            Sign out
        </MenuItem>
    </IconMenu>
)

function LoginStatus() {
    const {api, userInfo} = useApi();
    if(!userInfo) {
        return <Button href={url_for('home')}>Sign in</Button>;
    } else {
        return (
            <div>
                Signed in as {userInfo['name']}
                <Button onClick={() => api.logout(url_for('home'))}>Sign out</Button>
            </div>
        );
    }
}

function AppBar({
    title, backLink, rightControls, userInfo,
    onBack, onClickMenu, onSignout, children
}) {
    const classes = useStyles();
    return (
        <MUIAppBar color='default' position='static' className={classes.appBar}>
            <Toolbar>
                {(backLink || onBack)
                    ? <BackButton href={backLink} onClick={onBack}/>
                    : <MenuButton onClick={onClickMenu}/>}
                <h1 className={classes.grow}>{title}</h1>
                <div className={classes.spacer}/>
                {rightControls}
                {userInfo && <span>{userInfo.username}</span>}
                <LoginStatus />
                <RightMenu onSignout={onSignout}/>
            </Toolbar>
            {children}
        </MUIAppBar>
    )
}

function MainNav({open, onClose, onSignout}) {
    return (
        <Drawer open={open} onClose={onClose}>
            <List>
                {fp.map(l => (
                    <ListItem button component={Link} key={l.route} to={url_for(l.route)}>
                        <ListItemIcon><Icon>{l.icon}</Icon></ListItemIcon>
                        <ListItemText primary={l.primary}/>
                    </ListItem>
                ))(links)}
                <ListItem button onClick={onSignout}>
                    <ListItemIcon><Icon>power_settings_new</Icon></ListItemIcon>
                    <ListItemText primary="Sign Out"/>
                </ListItem>
            </List>
        </Drawer>

    )
}

export default function BasePage({
    title, pageTitle, backLink, onBack, children,
    ...props
}) {
    const [navOpen, setNavOpen] = useState(false);
    const classes = useStyles();
    return (
        <DocumentTitle title={`${pageTitle} | Thanos`}>
            <div className={classes.page} {...props}>
                <AppBar title={title} onClickMenu={() => setNavOpen(true)}/>
                <MainNav open={navOpen} onClose={() => setNavOpen(false)}/>
                <Paper className={classes.container}>
                    <Alerts />
                    {children}
                </Paper>
            </div>
        </DocumentTitle>
    )
}
