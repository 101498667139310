import React from 'react'
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        backgroundColor: theme.palette.hero,
        backgroundSize: 'cover',
        height: theme.layout.heroTotalHeight,
        zIndex: -1,
    },
    overlay: {
        width: '100%',
        height: '100%',
    },
}));

export default function CoverImage({src, dark}) {
    const classes = useStyles()
    return (
        <div className={classes.root} style={{backgroundImage: `url(${src})`}}>
            <div className={classes.overlay} style={{backgroundColor: `rgba(0, 0, 0, ${dark}`}}/>
        </div>
    );
}
