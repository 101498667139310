import React from 'react';
import fp from 'lodash/fp';
import {TextField as MUITextField} from '@material-ui/core';
import {Autocomplete as MUIAutocomplete} from '@material-ui/lab';

import {DrivePicker} from 'components/GAPI';

export function makeField(component) {
    const Component = component;
    return ({field, form, ...props}) => {
        const touched = form.submitCount > 0 || fp.get(field.name, form.touched);
        const errors = fp.get(field.name, form.errors);
        return <Component
            {...field}
            error={!!(touched && errors)}
            helperText={touched && errors}
            {...props}/>
    }
}

export function TextField({field, form, ...props}) {
    const touched = form.submitCount > 0 || fp.get(field.name, form.touched);
    const errors = fp.get(field.name, form.errors);
    return <MUITextField
        {...field}
        error={!!(touched && errors)}
        helperText={touched && errors}
        {...props}/>
}


export function DrivePickerField({field, form, ...props}) {
    const touched = form.submitCount > 0 || fp.get(field.name, form.touched);
    const errors = fp.get(field.name, form.errors);
    return <DrivePicker
        {...field}
        error={!!(touched && errors)}
        helperText={touched && errors}
        {...props}
        />
}

export function Autocomplete({
    field, form,
    label, name, options, multiple,
}) {
    return <MUIAutocomplete
        options={options}
        value={field.value}
        onChange={field.onChange}
        getOptionLabel={option => option.label}
        renderInput={props => <TextField {...props} label={label} />}
        multiple={multiple}
    />
}
        
