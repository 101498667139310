import {useState, useEffect, useCallback} from 'react';

export const wrapAsync = fn => (...args) => fn(...args).catch(error => {
    console.error('Async wrapper error', error);
})

export const useAsyncMemo = (f, deps=[]) => {
    const [value, setValue] = useState();
    useEffect(
        () => {f().then(setValue)},
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps
    )
    return value;
}

export const useStorage = (storage, key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = storage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error('Error loading storage', error);
            return initialValue;
        }
    });

    const setValue = useCallback(
        value => {
            try {
                const valueToStore = (
                    value instanceof Function
                    ? value(storedValue)
                    : value
                );
                setStoredValue(valueToStore);
                storage.setItem(key, JSON.stringify(valueToStore));
            } catch (error) {
                // A more advanced implementation would handle the error case
                console.error('Error saving storage', error);
            }
        },
        [storage, key, storedValue]
    );

    return [storedValue, setValue];
}

