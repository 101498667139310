import React, {useCallback, useState, useEffect, useRef} from 'react';
import fp from 'lodash/fp';
import downloadjs from 'downloadjs';

import MaterialTable from 'material-table';

import {useApi} from 'lib/api';

import DynamoPagination from './DynamoPagination';

const attrName = col => col.field.split('.', 2)[1];

export default function DynamoTable({
    link, tableRef, options={}, components={}, actions=[],
    downloadLink, ...props
}) {
    const {api} = useApi();

    const [firstLink, setFirstLink] = useState(link);
    const [curLink, setCurLink] = useState(link);
    const [nextLink, setNextLink] = useState(null);
    const privateRef = useRef();

    tableRef = tableRef || privateRef;

    const fetchData = useCallback(async ({
        filters, orderBy, orderDirection, page, pageSize, search, totalCount
    }) => {
        if(!api || !link) {
            return {data: [], page: 0, totalCount: 0};
        }
        let fetchLink = (
            page === 0 ? (firstLink || link)
            : page === 1 ? (curLink || link)
            : nextLink || link
        )
        console.log('Fetch page', {firstLink, link, page, link, fetchLink});
        let url = new URL(fetchLink);
        url.searchParams.set('page[limit]', pageSize);
        fp.forEach(
            ({column, value}) => {
                let k = attrName(column);
                url.searchParams.set(`filter[${k}]`, value);
            },
            filters
        )
        let resp = await api.fetch(url);
        let data = await resp.json();
        console.log('Fetched', data);
        setFirstLink(data.links.first);
        setCurLink(data.links.self);
        setNextLink(data.links.next);
        return {
            data: data.data,
            included: data.included,
            page: (data.links.self === firstLink ? 0
                : data.links.next ? 1
                : 2
            ),
            totalCount: 3 * pageSize,
        }
    }, [api, link, firstLink, curLink, nextLink])

    const refresh = useCallback(
        () => tableRef.current.onQueryChange(),
        [tableRef]
    )

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [tableRef, link])

    const download = useCallback(async () => {
        let resp = await api.fetch(downloadLink, {
            method: 'POST',
            body: JSON.stringify({expires_in: 50}),
        })
        let json = await resp.json();
        console.log('About to download', json.href);
        window.open(json.href, '_blank')
        // downloadjs(json.href);
    }, [downloadLink])

    let tableActions = [
        {
            icon: 'refresh',
            tooltip: 'Refresh',
            isFreeAction: true,
            onClick: refresh,
        },
    ]

    if(downloadLink) {
        tableActions.push({
            icon: 'cloud_download',
            tooltip: 'Download (csv)',
            isFreeAction: true,
            onClick: download,
        })
    }

    return <MaterialTable
        options={{
            pageSize: 15,
            pageSizeOptions: [5, 15, 30],
            ...options,
            sorting: false,
            search: false,
        }}
        components={{...components, Pagination: DynamoPagination}}
        tableRef={tableRef}
        data={fetchData}
        actions={[...tableActions, ...actions]}
        {...props}
    />
}
