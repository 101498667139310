import React, {useCallback, useMemo} from 'react';
import fp from 'lodash/fp';

import {TextField, MenuItem} from '@material-ui/core';
import MaterialTable from 'material-table';

function ItemDeathRuleSelect({
    itemDeathRuleNames, rowData, value='', onChange, ...extraProps
}) {
    const handleChange = useCallback(
        e => onChange(e.target.value),
        [onChange]
    );
    return (
        <TextField select value={value} onChange={handleChange}>
            {fp.map(
                idr => <MenuItem key={idr} value={idr}>{idr}</MenuItem>,
                itemDeathRuleNames
            )}
        </TextField>
    )
}

const makeColumns = form => {
    const itemDeathRuleNames = fp.reject(
        name => name === 'txn_last_120d',
        form.values.attributes.item_death_rules.columns
    );
    return ([
        {title: 'Name', field: 'name'},
        {
            title: 'Item Death Rule', field: 'item_death_rule',
            editComponent: props => <ItemDeathRuleSelect
                itemDeathRuleNames={itemDeathRuleNames} {...props}
            />
        },
        {title: 'Annual Variable Holding Cost', field: 'annual_variable_holding_cost'},
        {title: 'Receipt Line Cost (fixed)', field: 'receipt_line_fixed_cost'},
        {title: 'Backorder Line Cost (fixed)', field: 'backorder_line_fixed_cost'},
        {title: 'Backorder Cost (variable)', field: 'backorder_variable_cost'},
        {title: 'Spill Line Cost (fixed)', field: 'spill_line_fixed_cost'},
        {title: 'Spill Cost (variable)', field: 'spill_variable_cost'},
        {title: 'Stockout Spill Rate)', field: 'stockout_spill_rate'},
    ]);
};

export default function ScenarioRulesTable({
    title, name, form, push, replace, remove
}) {
    const columnData = useMemo(
        () => makeColumns(form),
        [form]
    );

    const rowData = useMemo(
        () => fp.get(name, form.values),
        [name, form]
    );

    const onRowAdd = async rowData => {
        push(rowData);
    }

    const onRowUpdate = async (newData, oldData) => {
        const {tableData} = oldData;
        replace(tableData.id, newData)
    }

    const onRowDelete = async rowData => {
        const {tableData} = rowData;
        remove(tableData.id)
    }

    return <MaterialTable title={title}
        options={{
            search: false,
            pageSize: 15,
            pageSizeOptions: [5, 15, 30],
        }}
        editable={{onRowAdd, onRowUpdate, onRowDelete}}
        columns={columnData}
        data={rowData}
    />
}