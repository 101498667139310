import React, {createContext, useContext} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Route, Switch} from 'react-router';

import routes from './routes';

import Home from './pages/Home';
import AuthCallback from './pages/AuthCallback';
import ClientCollection from './pages/ClientCollection';
import Client from './pages/Client';
import ModelSet from './pages/ModelSet';
import OptimizationJob from './pages/OptimizationJob';

const Context = createContext();

const CustomBrowserRouter = ({children}) => (
  <BrowserRouter>
    <Route>
      {(routeProps) => <Context.Provider value={routeProps} children={children}/>}
    </Route>
  </BrowserRouter>
)

export const useRouter = () => useContext(Context);

export const Router = () => (
    <CustomBrowserRouter>
        <Switch>
            <Route exact path={routes.home.path} component={Home}/>
            <Route exact path={routes.callback.path} component={AuthCallback}/>
            <Route exact path={routes.clients.path} component={ClientCollection}/>
            <Route exact path={routes.client.path} component={Client}/>
            <Route exact path={routes.modelSet.path} component={ModelSet}/>
            <Route exact path={routes.optimizationJob.path} component={OptimizationJob}/>
        </Switch>
    </CustomBrowserRouter>
)

export default Router;
