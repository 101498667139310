import React, {useMemo, useCallback} from 'react';
import fp from 'lodash/fp';
import {makeStyles} from '@material-ui/core/styles';
import {
    TextField, MenuItem, FormControl, Input, InputAdornment, InputLabel,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    editor: {
        display: 'flex',
        alignItems: 'baseline',
    },
    weekday: {
        minWidth: theme.spacing(20),
    },

}));


const daysOfWeek = [
    {value: 'SU', label: 'Sunday'},
    {value: 'MO', label: 'Monday'},
    {value: 'TU', label: 'Tuesday'},
    {value: 'WE', label: 'Wednesday'},
    {value: 'TH', label: 'Thursday'},
    {value: 'FR', label: 'Friday'},
    {value: 'SA', label: 'Saturday'},
]

const daysOfWeekIndex = fp.flow(
    fp.map(obj => ([obj.value, obj.label])),
    fp.fromPairs
)(daysOfWeek);

// Adapted from https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number/13627586
function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return "st";
    }
    if (j === 2 && k !== 12) {
        return "nd";
    }
    if (j === 3 && k !== 13) {
        return "rd";
    }
    return "th";
}

const parseValue = ({bymonthday, byday}) => {
    if(byday) {
        const match = byday.match(/[+]?(\d+)(.*)/)
        if(match) {
            return {
                number: Number(match[1]),
                dow: match[2],
            };
        }
    }
    return {number: bymonthday};
}

const OrdinalTextField = ({
    label,
    ...props
}) => (
    <FormControl>
        <InputLabel>{label}</InputLabel>
        <Input {...props} endAdornment={
            <InputAdornment position="end">
                {ordinal_suffix_of(props.value)}
            </InputAdornment>
        }/>
    </FormControl>
)


export function ByDayView(rowData) {
    const {number, dow} = useMemo(
        () => parseValue(rowData),
         [rowData]
    );
    const suffix = ordinal_suffix_of(number);
    return (
        <React.Fragment>
            {'' + number}{suffix} {dow && daysOfWeekIndex[dow]}
        </React.Fragment>
    )
}

export function ByDayEdit({rowData, onRowDataChange}) {
    const {number, dow} = useMemo(
        () => parseValue(rowData),
         [rowData]
    );
    const changeNumber = useCallback(
        e => {
            if(dow) {
                onRowDataChange({
                    ...rowData,
                    bymonthday: undefined,
                    byday: `${e.target.value}${dow}`,
                })
            } else {
                onRowDataChange({
                    ...rowData,
                    bymonthday: e.target.value,
                    byday: undefined,
                })
            }
        }, [dow, rowData, onRowDataChange]
    )
    const changeDow = useCallback(
        e => {
            if(e.target.value) {
                onRowDataChange({
                    ...rowData,
                    bymonthday: undefined,
                    byday: `${number}${e.target.value}`
                })
            } else {
                onRowDataChange({
                    ...rowData,
                    bymonthday: number,
                    byday: undefined,
                })
            }
        }, [number, rowData, onRowDataChange]
    )

    const classes = useStyles();

    return (
        <div className={classes.editor}>
            <OrdinalTextField type="number" value={number || ''} onChange={changeNumber}/>
            &nbsp;
            <TextField select className={classes.weekday} label="(day of week)"
                value={dow || ''} onChange={changeDow}
            >
                <MenuItem value=""/>
                {fp.map(
                    item => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                , daysOfWeek)}
            </TextField>
        </div>
    )
}
